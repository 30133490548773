import Button from 'components/button';
import './footer.css';

import React, { useState } from 'react';
import { social } from 'config';

const Footer = () => {
    return (
        <>
            <div className={`footer`}>
                <div className="container _wide">
                    <div className="footer__groups">
                        <div className="footer__group">
                            <h4>Нормальный Дубай</h4>
                            <p>
                                Сервисы для тех, кто переезжает в&nbsp;Эмираты,
                                обустраивается здесь и&nbsp;начинает
                                зарабатывать. Журнал &laquo;Силачи духа&raquo;
                                с&nbsp;историями переехавших, инструкциями
                                и&nbsp;объяснением дубайской жизни. Сообщество
                                резидентов с&nbsp;дружеским чатом
                                и&nbsp;мероприятиями.
                            </p>
                        </div>
                        <div className="footer__menu">
                            <div className="footer__menu-item">
                                <h4>Комьюнити</h4>
                                <p>
                                    <a
                                        href="https://t.me/vstrechi_silachey"
                                        target="_blank"
                                    >
                                        Канал
                                    </a>{' '}
                                    с анонсами мероприятий
                                </p>
                                <p>
                                    <a
                                        href="https://t.me/+aiziSNA2YRQxNjZi"
                                        target="_blank"
                                    >
                                        Дружеский чат
                                    </a>
                                </p>
                            </div>
                            <div className="footer__menu-item">
                                <h4>&laquo;Силачи духа&nbsp;/ Дубай&raquo;</h4>
                                <p>
                                    <a
                                        href="https://t.me/silachi_duha"
                                        target="_blank"
                                    >
                                        Телеграм
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="https://www.instagram.com/silachi__duha_dubai?igsh=MWNiOW9lMWtwdmRzbA==&utm_source=qr"
                                        target="_blank"
                                    >
                                        Инстраграм
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="footer__dev">
                        Сделано в{' '}
                        <a href="https://dears.agency/" target="_blank">
                            Dears
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
