import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getService,
    getServiceSuccess,
    getServiceFailure,
    getServiceIdle,
} from './actions';

import { Post } from 'types/models';
import { getOgReguest, getReguest } from '../app/api';

function* getSagaService(action: any): any {
    try {
        const { payload } = action;

        const { slug } = payload;

        let route =
            '/api/services/?filters[Slug]=' +
            slug +
            '&fields[0]=Title' +
            '&fields[1]=OG_Title' +
            '&fields[2]=OG_Description' +
            '&populate=Preview' +
            '&populate=OG_Image' +
            '&populate=Content' +
            '&pagination[pageSize]=1';

        let [service] = yield all([call(getReguest, { route: route })]);

        console.log(service);

        if (
            typeof window === 'undefined' &&
            service &&
            service.data &&
            service.data[0] &&
            service.data[0].attributes
        ) {
            const preview = service.data[0].attributes.Preview;

            const category = service.data[0].attributes.Post_category;
            let image, rubric;
            const text = service.data[0].attributes.Title;
            let request: {
                text: string;
                image?: string;
                rubric?: string;
            } = {
                text: text,
            };
            if (
                preview &&
                preview.data &&
                preview.data &&
                preview.data.attributes
            ) {
                image = preview.data.attributes.url;

                request = { ...request, image };
            }
            if (category && category.data && category.data.attributes) {
                rubric = category.data.attributes.Title;
                request = { ...request, rubric };
            }
            console.log('request', request);
            const og = yield call(getOgReguest, request);
            console.log('og', og);
            if (og.url) {
                service = { ...service, ogurl: og.url };
            }
        }

        yield put(
            getServiceSuccess({
                service,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getServiceFailure(error));
    }
}

export function* serviceWatcher() {
    yield takeEvery(getService.toString(), getSagaService);
}
