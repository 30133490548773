import { createActions } from 'redux-actions';

const {
    getOffers,
    getOffersSuccess,
    getOffersFailure,
    getOffersIdle,
    getOffersClear,
}: {
    getOffers?: any;
    getOffersSuccess?: any;
    getOffersFailure?: any;
    getOffersIdle?: any;
    getOffersClear?: any;
} = createActions(
    'GET_OFFERS',
    'GET_OFFERS_SUCCESS',
    'GET_OFFERS_FAILURE',
    'GET_OFFERS_IDLE',
    'GET_OFFERS_CLEAR'
);

export {
    getOffers,
    getOffersSuccess,
    getOffersFailure,
    getOffersIdle,
    getOffersClear,
};
