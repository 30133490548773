import { Link } from 'react-router-dom';
import './button.css';

import React from 'react';

const Button = ({
    children,
    type = 'button',
    className = '_default',
    active = false,
    attributes,
    href,
}: {
    children?: any;
    type?: any;
    className?: any;
    attributes?: any;
    active?: any;
    href?: any;
}) => {
    return (
        <>
            {href &&
                (href.indexOf('normdubai.com') > -1 ||
                    href.indexOf('/') === 0) && (
                    <Link
                        to={href}
                        className={`button ${className} ${
                            (active && '_dark') || ''
                        }`}
                        {...attributes}
                    >
                        {children}
                    </Link>
                )}
            {href &&
                href.indexOf('normdubai.com') == -1 &&
                href.indexOf('/') !== 0 && (
                    <a
                        href={href}
                        target="_blank"
                        className={`button ${className} ${
                            (active && '_dark') || ''
                        }`}
                        {...attributes}
                    >
                        {children}
                    </a>
                )}
            {!href && (
                <button
                    type={type}
                    className={`button ${className} ${
                        (active && '_dark') || ''
                    }`}
                    {...attributes}
                >
                    {children}
                </button>
            )}
        </>
    );
};

export default Button;
