import loadable from '@loadable/component';
import moment from 'moment';
import { Router, Switch, Route } from 'react-router-dom';
// import { fetchHomepage } from 'store/ducks/homepage/actions';
// import { fetchNews } from 'store/ducks/news/actions';
// import { getNews } from './components/News/actions';
// import { fetchShoes } from 'store/ducks/shoes/actions';
import { RouterFetchDataArgs } from 'types';

import { getInitData } from 'store/ducks/app/actions';
import { getServices } from 'store/ducks/services/actions';
import { getService } from 'store/ducks/service/actions';
import { getHomepage } from 'store/ducks/homepage/actions';
import { getPost } from 'store/ducks/post/actions';
import { getOffer } from 'store/ducks/offer/actions';
import { getHistory } from 'store/ducks/history/actions';
import { getOffers } from 'store/ducks/offers/actions';
import {
    getEventMonth,
    getEventsFuture,
    getEventsPast,
} from 'store/ducks/events/actions';
import { getEvent } from 'store/ducks/event/actions';
// import UI from 'pages/UI/Ui';

const UiPage = loadable(() => import('./pages/UI/UI'));
const HomePage = loadable(() => import('./pages/Home/Home'));
const ServicesPage = loadable(() => import('./pages/Services/Services'));
const ServicePage = loadable(() => import('./pages/Service/Service'));
const PostPage = loadable(() => import('./pages/Post/Post'));
const OfferPage = loadable(() => import('./pages/Offer/Offer'));
const HistoryPage = loadable(() => import('./pages/History/History'));
const OffersPage = loadable(() => import('./pages/Offers/Offers'));
const EventsPage = loadable(() => import('./pages/Events/Events'));
const EventPage = loadable(() => import('./pages/Event/Event'));

/**
 * Routes are moved to a separate file,
 * so that you can use the asyncFetchData method on the component on the server (by url path)
 * which load all the necessary data for rendering the page.
 */
export default [
    {
        path: '/',
        component: HomePage,
        exact: true,
        fetchData({ dispatch }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(getHomepage());
        },
    },
    {
        path: '/events',
        component: EventsPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(
                getEventsPast({
                    filter:
                        '&filters[Start][$lte]=' +
                        moment().format('YYYY-MM-DD'),
                })
            );
            dispatch(
                getEventsFuture({
                    filter:
                        '&filters[Start][$gte]=' +
                        moment().format('YYYY-MM-DD'),
                })
            );
            dispatch(getEventMonth());
            // dispatch(
            //     getHistory({
            //         filter: '&pagination[start]=0&pagination[limit]=8',
            //     })
            // );
        },
    },
    {
        path: '/events/:slug',
        component: EventPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(getHomepage());
            dispatch(
                getEvent({
                    slug: match.params.slug.replace('null', ''),
                })
            );
        },
    },
    {
        path: '/history',
        component: HistoryPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(
                getHistory({
                    filter: '&pagination[start]=0&pagination[limit]=8',
                })
            );
        },
    },
    {
        path: '/history/:slug',
        component: HistoryPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(
                getHistory({
                    filter:
                        '&pagination[start]=0&pagination[limit]=4&filters[Post_category][id][$in]=' +
                        match.params.slug,
                })
            );
        },
    },
    {
        path: '/offers',
        component: OffersPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(
                getOffers({
                    filter: '&pagination[start]=0&pagination[limit]=8',
                })
            );
        },
    },
    {
        path: '/offers/:slug',
        component: OfferPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(getHomepage());
            dispatch(
                getOffer({
                    slug: match.params.slug.replace('null', ''),
                })
            );
        },
    },
    {
        path: '/services/:slug',
        component: ServicePage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(
                getService({
                    slug: match.params.slug,
                })
            );
        },
    },
    {
        path: '/services',
        component: ServicesPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(getServices());
        },
    },
    {
        path: '/posts/:slug',
        component: PostPage,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(getInitData());
            dispatch(getHomepage());
            dispatch(
                getPost({
                    slug: match.params.slug.replace('null', ''),
                })
            );
        },
    },
    {
        path: '/ui',
        component: UiPage,
        exact: true,
    },
];
