import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getPost, getPostSuccess, getPostIdle } from './actions';
import { PostState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: PostState = {
    status: 'IDLE',
    data: {},
};

const status = handleActions<StateStatus>(
    {
        [getPost]: () => 'LOADING',
        [getPostSuccess]: () => 'SUCCESS',
        [getPostIdle]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getPostSuccess]: (state, action) => ({
            ...action.payload.post.data[0],
            ogurl: action.payload.post.ogurl,
        }),
    },
    initialState.data
);

export const post = combineReducers({
    status,
    data,
});
