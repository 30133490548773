import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getEventsFuture,
    getEventsFutureSuccess,
    getEventsFutureFailure,
    getEventsFutureIdle,
    getEventsFutureClear,
    getEventsPast,
    getEventsPastSuccess,
    getEventsPastFailure,
    getEventsPastIdle,
    getEventsPastClear,
    getEventMonth,
    getEventMonthSuccess,
    getEventMonthFailure,
    getEventMonthIdle,
    getEventMonthClear,
} from './actions';
import { EventsState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: EventsState = {
    statusFuture: 'IDLE',
    statusPast: 'IDLE',
    futureEvents: [],
    pastEvents: [],
    eventMonth: {},
};

const statusFuture = handleActions<StateStatus>(
    {
        [getEventsFuture]: () => 'LOADING',
        [getEventsFutureSuccess]: () => 'SUCCESS',
        [getEventsFutureIdle]: () => 'IDLE',
    },
    initialState.statusFuture
);

const statusPast = handleActions<StateStatus>(
    {
        [getEventsPast]: () => 'LOADING',
        [getEventsPastSuccess]: () => 'SUCCESS',
        [getEventsPastIdle]: () => 'IDLE',
    },
    initialState.statusPast
);

const futureEvents = handleActions(
    {
        [getEventsFutureSuccess]: (state, action) => {
            return [...state, ...action.payload.futureEvents.data];
        },
        [getEventsFutureClear]: () => [],
    },
    initialState.futureEvents
);

const pastEvents = handleActions(
    {
        [getEventsPastSuccess]: (state, action) => {
            return [...state, ...action.payload.pastEvents.data];
        },
        [getEventsPastClear]: () => [],
    },
    initialState.pastEvents
);

const eventMonth = handleActions(
    {
        [getEventMonthSuccess]: (state, action) => {
            return action.payload.eventMonth.data[0];
        },
        [getEventMonthClear]: () => [],
    },
    initialState.eventMonth
);

export const events = combineReducers({
    statusFuture,
    statusPast,
    futureEvents,
    pastEvents,
    eventMonth,
});
