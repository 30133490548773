import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getOffers,
    getOffersSuccess,
    getOffersFailure,
    getOffersIdle,
    getOffersClear,
} from './actions';
import { OffersState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: OffersState = {
    posts: [],
    statusPosts: 'IDLE',
    postsMeta: null,
};

const statusPosts = handleActions<StateStatus>(
    {
        [getOffers]: () => 'LOADING',
        [getOffersSuccess]: () => 'SUCCESS',
        [getOffersIdle]: () => 'IDLE',
    },
    initialState.statusPosts
);

const posts = handleActions(
    {
        [getOffersSuccess]: (state, action) => {
            return [...state, ...action.payload.posts.data];
        },
        [getOffersClear]: () => [],
    },
    initialState.posts
);

const postsMeta = handleActions(
    {
        [getOffersSuccess]: (state, action) => action.payload.posts.meta,
        [getOffersClear]: () => null,
    },
    initialState.postsMeta
);

export const offers = combineReducers({
    posts,
    statusPosts,
    postsMeta,
});
