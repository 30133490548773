import { token, domain } from 'config';
import fetch from 'isomorphic-fetch';

export const getReguest = ({ route, id }: any) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + token);

        var requestOptions: { method: any; headers: any; redirect: any } = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(domain + route, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(route, result);
                if (id) {
                    resolve({
                        [id]: result,
                    });
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                // console.log(route, error);
                reject(error);
            });
    });
};
