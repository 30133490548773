import Button from 'components/button';
import './search.css';

import React, { useEffect, useState } from 'react';
import { menu, social } from 'config';
import { Link, useHistory, useParams } from 'react-router-dom';
import Footer from 'layout/footer';
import { useDispatch, useSelector } from 'react-redux';

const Search = (props: any) => {
    const categories = useSelector(
        (state: { search: any }) => state.search.categories
    );

    const data = useSelector((state: { search: any }) => state.search.data);

    const meta = useSelector((state: { search: any }) => state.search.meta);

    return (
        <div className="search">
            <div className="search-form">
                <input
                    type="text"
                    value={props.value}
                    className="search-form-input"
                    placeholder="Поиск"
                    onChange={props.handleChange}
                    onKeyDown={props.handleKeyDown}
                />
                {props.value && props.value.length > 0 && (
                    <a
                        href="#"
                        onClick={props.handleClear}
                        className="search-form-clear"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_3454_1580)">
                                <rect
                                    width="24"
                                    height="24"
                                    rx="12"
                                    fill="#353131"
                                    fill-opacity="0.1"
                                />
                                <path
                                    d="M6 6L18 18M18 6L6 18"
                                    stroke="#353131"
                                    stroke-opacity="0.4"
                                    stroke-width="2"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_3454_1580">
                                    <rect
                                        width="24"
                                        height="24"
                                        rx="12"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                )}
            </div>
            {props.full == true && (
                <>
                    {data &&
                        data.length > 0 &&
                        categories &&
                        Object.keys(categories).length > 0 && (
                            <div className="search-filter">
                                {Object.keys(categories).map((item: any) => {
                                    return (
                                        <Button
                                            key={item}
                                            active={
                                                props.activeCategory == item
                                            }
                                            attributes={{
                                                onClick: () => {
                                                    props.setActiveCategory(
                                                        item
                                                    );
                                                },
                                            }}
                                        >
                                            {categories[item].title} (
                                            {categories[item].items.length})
                                        </Button>
                                    );
                                })}
                            </div>
                        )}
                    {(data && data.length > 0 && (
                        <div className="search-results">
                            {data.map((item: any) => {
                                var blockquoteText = item.attributes.Title;
                                let symbolToHighlight = props.value;
                                let regex = new RegExp(symbolToHighlight, 'g');
                                let newBlockquoteText = blockquoteText.replace(
                                    regex,
                                    '<span class="highlight">' +
                                        symbolToHighlight +
                                        '</span>'
                                );
                                let highLightTitle = newBlockquoteText;

                                if (
                                    parseInt(props.activeCategory) !== 0 &&
                                    item.attributes.Post_category &&
                                    parseInt(props.activeCategory) !==
                                        item.attributes.Post_category.data.id
                                ) {
                                    return;
                                }

                                return (
                                    <div className="search-results-item">
                                        <Link to={`/posts/${item.id}`}>
                                            <span
                                                className="search-results-item-title"
                                                dangerouslySetInnerHTML={{
                                                    __html: highLightTitle,
                                                }}
                                            ></span>
                                            <span className="search-results-item-lead">
                                                {item.attributes.Description}
                                            </span>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    )) ||
                        (meta &&
                            meta.pagination &&
                            meta.pagination.total == 0 && (
                                <div className="search-not-found">
                                    Ничего не нашлось
                                </div>
                            ))}
                </>
            )}
        </div>
    );
};

export default Search;
