import { createActions } from 'redux-actions';

const {
    getHistory,
    getHistorySuccess,
    getHistoryFailure,
    getHistoryIdle,
    getHistoryClear,
}: {
    getHistory?: any;
    getHistorySuccess?: any;
    getHistoryFailure?: any;
    getHistoryIdle?: any;
    getHistoryClear?: any;
} = createActions(
    'GET_HISTORY',
    'GET_HISTORY_SUCCESS',
    'GET_HISTORY_FAILURE',
    'GET_HISTORY_IDLE',
    'GET_HISTORY_CLEAR'
);

export {
    getHistory,
    getHistorySuccess,
    getHistoryFailure,
    getHistoryIdle,
    getHistoryClear,
};
