import { createActions } from 'redux-actions';

const {
    getService,
    getServiceSuccess,
    getServiceFailure,
    getServiceIdle,
}: {
    getService?: any;
    getServiceSuccess?: any;
    getServiceFailure?: any;
    getServiceIdle?: any;
} = createActions(
    'GET_SERVICE',
    'GET_SERVICE_SUCCESS',
    'GET_SERVICE_FAILURE',
    'GET_SERVICE_IDLE'
);

export { getService, getServiceSuccess, getServiceFailure, getServiceIdle };
