import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import { getHistory, getHistorySuccess, getHistoryFailure } from './actions';

import { getReguest } from '../app/api';

function* getHistorySaga(action: any): any {
    try {
        const { payload } = action;

        const { filter } = payload;

        const [posts] = yield all([
            call(getReguest, {
                route:
                    '/api/posts?' +
                    'fields[0]=Title' +
                    '&fields[1]=Background' +
                    '&fields[2]=Description' +
                    '&fields[3]=Slug' +
                    '&fields[4]=IsMaskPreview' +
                    '&populate=Post_category' +
                    '&populate=Preview' +
                    '&populate=Emoji' +
                    filter,
            }),
        ]);

        yield put(
            getHistorySuccess({
                posts,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getHistoryFailure(error));
    }
}

export function* historyWatcher() {
    yield takeEvery(getHistory.toString(), getHistorySaga);
}
