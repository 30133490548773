import { createActions } from 'redux-actions';

const {
    getOffer,
    getOfferSuccess,
    getOfferFailure,
    getOfferIdle,
}: {
    getOffer?: any;
    getOfferSuccess?: any;
    getOfferFailure?: any;
    getOfferIdle?: any;
} = createActions(
    'GET_OFFER',
    'GET_OFFER_SUCCESS',
    'GET_OFFER_FAILURE',
    'GET_OFFER_IDLE'
);

export { getOffer, getOfferSuccess, getOfferFailure, getOfferIdle };
