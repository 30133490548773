import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
} from './actions';

import { Post } from 'types/models';
import { getReguest } from '../app/api';

function* getSagaSearch(action: any): any {
    try {
        const { payload } = action;

        const { query, category } = payload;

        let route =
            '/api/posts?fields[0]=Title&fields[1]=Description&fields[2]=Slug&populate[0]=Post_category';

        if (query && query !== '') {
            route =
                route +
                '&filters[$or][0][Title][$containsi]=' +
                query +
                '&filters[$or][1][Keywords][$containsi]=' +
                query;
        }

        // if (category && category !== '') {
        //     let index = 2;
        //     if (!query || !query.length) {
        //         index = 0;
        //     }
        //     route = route + '&filters[Post_category][id][$in]=' + category;
        // }

        route = route + '&pagination[page]=1&pagination[pageSize]=50';

        let routeAll =
            '/api/posts?fields[0]=Title&fields[1]=Description&fields[2]=Slug&populate[0]=Post_category';

        if (query && query !== '') {
            routeAll =
                routeAll +
                '&filters[$or][0][Title][$containsi]=' +
                query +
                '&filters[$or][1][Keywords][$containsi]=' +
                query;
        }

        routeAll = routeAll + '&pagination[page]=1&pagination[pageSize]=50';

        const [posts, postsAll] = yield all([
            call(getReguest, { route: route }),
            call(getReguest, { route: routeAll }),
        ]);

        console.log(postsAll);

        let categories: any = {
            0: {
                title: 'Все',
                items: [],
            },
        };

        if (postsAll && postsAll.data && postsAll.data.length > 0) {
            postsAll.data.map((item: any) => {
                if (categories[item.attributes.Post_category.data.id]) {
                    categories = {
                        ...categories,
                        [item.attributes.Post_category.data.id]: {
                            ...categories[
                                item.attributes.Post_category.data.id
                            ],
                            items: [
                                ...categories[
                                    item.attributes.Post_category.data.id
                                ].items,
                                item,
                            ],
                        },
                    };
                } else {
                    categories = {
                        ...categories,
                        [item.attributes.Post_category.data.id]: {
                            title:
                                (item.attributes.Post_category.data &&
                                    item.attributes.Post_category.data
                                        .attributes &&
                                    item.attributes.Post_category.data
                                        .attributes.Title) ||
                                '',
                            items: [item],
                        },
                    };
                }
                categories = {
                    ...categories,
                    0: {
                        ...categories[0],
                        items: [...categories[0].items, item],
                    },
                };
                console.log(categories);
            });
        }

        console.log(posts);

        yield put(
            getSearchSuccess({
                posts,
                categories,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getSearchFailure(error));
    }
}

export function* searchWatcher() {
    yield takeEvery(getSearch.toString(), getSagaSearch);
}
