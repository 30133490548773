import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getInitData, getInitDataSuccess, getInitDataIdle } from './actions';
import { StateStatus } from './types';

export const initialState: {
    status: string | any;
    categories: any;
    eventCategories: any;
    offerCategories: any;
} = {
    status: 'IDLE',
    categories: [],
    eventCategories: [],
    offerCategories: [],
};

const status = handleActions<StateStatus>(
    {
        [getInitData]: () => 'LOADING',
        [getInitDataSuccess]: () => 'SUCCESS',
        [getInitDataIdle]: () => 'IDLE',
    },
    initialState.status
);

const categories = handleActions(
    {
        [getInitDataSuccess]: (state: any, action: any) =>
            action.payload.categories,
    },
    initialState.categories
);

const eventCategories = handleActions(
    {
        [getInitDataSuccess]: (state: any, action: any) =>
            action.payload.eventCategories,
    },
    initialState.eventCategories
);

const offerCategories = handleActions(
    {
        [getInitDataSuccess]: (state: any, action: any) =>
            action.payload.offerCategories,
    },
    initialState.offerCategories
);

export const initData = combineReducers({
    status,
    categories,
    eventCategories,
    offerCategories,
});
