import { call, put, takeEvery, all } from 'redux-saga/effects';

import { getReguest } from './api';

import {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
} from './actions';

function* getInitDataSaga(action: any): any {
    try {
        let routeCategories = '/api/post-categories';
        let routeEventCategories = '/api/event-categories';
        let routeOfferCategories = '/api/offer-categories';

        const [
            categoriesData,
            eventCategoriesData,
            offerCategoriesData,
        ] = yield all([
            call(getReguest, { route: routeCategories }),
            call(getReguest, { route: routeEventCategories }),
            call(getReguest, { route: routeOfferCategories }),
        ]);

        let categories = [
            {
                value: '',
                label: 'Все',
            },
        ];

        if (categoriesData && categoriesData.data) {
            categoriesData.data.map((category: any) => {
                categories.push({
                    value: category.id,
                    label: category.attributes.Title,
                });
            });
        }

        let eventCategories = [
            {
                value: '',
                label: 'Все',
            },
        ];

        if (eventCategoriesData && eventCategoriesData.data) {
            eventCategoriesData.data.map((category: any) => {
                eventCategories.push({
                    value: category.id,
                    label: category.attributes.Title,
                });
            });
        }

        let offerCategories = [
            {
                value: '',
                label: 'Все',
            },
        ];

        if (offerCategoriesData && offerCategoriesData.data) {
            offerCategoriesData.data.map((category: any) => {
                offerCategories.push({
                    value: category.id,
                    label: category.attributes.Title,
                });
            });
        }

        yield put(
            getInitDataSuccess({
                categories,
                eventCategories,
                offerCategories,
            })
        );
    } catch (error) {
        console.log(error);

        yield put(getInitDataFailure(error));
    }
}

export function* getInitDataWatcher() {
    yield takeEvery(getInitData.toString(), getInitDataSaga);
}
