import { createActions } from 'redux-actions';

const {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
    setCity,
    setSearch,
}: any = createActions(
    'GET_INIT_DATA',
    'GET_INIT_DATA_SUCCESS',
    'GET_INIT_DATA_FAILURE',
    'GET_INIT_DATA_IDLE',
    'SET_CITY',
    'SET_SEARCH'
);

export {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
    setCity,
    setSearch,
};
