import { createActions } from 'redux-actions';

const {
    getEventsFuture,
    getEventsFutureSuccess,
    getEventsFutureFailure,
    getEventsFutureIdle,
    getEventsFutureClear,
    getEventsPast,
    getEventsPastSuccess,
    getEventsPastFailure,
    getEventsPastIdle,
    getEventsPastClear,
    getEventMonth,
    getEventMonthSuccess,
    getEventMonthFailure,
    getEventMonthIdle,
    getEventMonthClear,
}: {
    getEventsFuture?: any;
    getEventsFutureSuccess?: any;
    getEventsFutureFailure?: any;
    getEventsFutureIdle?: any;
    getEventsFutureClear?: any;
    getEventsPast?: any;
    getEventsPastSuccess?: any;
    getEventsPastFailure?: any;
    getEventsPastIdle?: any;
    getEventsPastClear?: any;
    getEventMonth?: any;
    getEventMonthSuccess?: any;
    getEventMonthFailure?: any;
    getEventMonthIdle?: any;
    getEventMonthClear?: any;
} = createActions(
    'GET_EVENTS_FUTURE',
    'GET_EVENTS_FUTURE_SUCCESS',
    'GET_EVENTS_FUTURE_FAILURE',
    'GET_EVENTS_FUTURE_IDLE',
    'GET_EVENTS_FUTURE_CLEAR',
    'GET_EVENTS_PAST',
    'GET_EVENTS_PAST_SUCCESS',
    'GET_EVENTS_PAST_FAILURE',
    'GET_EVENTS_PAST_IDLE',
    'GET_EVENTS_PAST_CLEAR',
    'GET_EVENT_MONTH',
    'GET_EVENT_MONTH_SUCCESS',
    'GET_EVENT_MONTH_FAILURE',
    'GET_EVENT_MONTH_IDLE',
    'GET_EVENT_MONTH_CLEAR'
);

export {
    getEventsFuture,
    getEventsFutureSuccess,
    getEventsFutureFailure,
    getEventsFutureIdle,
    getEventsFutureClear,
    getEventsPast,
    getEventsPastSuccess,
    getEventsPastFailure,
    getEventsPastIdle,
    getEventsPastClear,
    getEventMonth,
    getEventMonthSuccess,
    getEventMonthFailure,
    getEventMonthIdle,
    getEventMonthClear,
};
