import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getHistory,
    getHistorySuccess,
    getHistoryFailure,
    getHistoryIdle,
    getHistoryClear,
} from './actions';
import { HistoryState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: HistoryState = {
    posts: [],
    statusPosts: 'IDLE',
    postsMeta: null,
};

const statusPosts = handleActions<StateStatus>(
    {
        [getHistory]: () => 'LOADING',
        [getHistorySuccess]: () => 'SUCCESS',
        [getHistoryIdle]: () => 'IDLE',
    },
    initialState.statusPosts
);

const posts = handleActions(
    {
        [getHistorySuccess]: (state, action) => {
            return [...state, ...action.payload.posts.data];
        },
        [getHistoryClear]: () => [],
    },
    initialState.posts
);

const postsMeta = handleActions(
    {
        [getHistorySuccess]: (state, action) => action.payload.posts.meta,
        [getHistoryClear]: () => null,
    },
    initialState.postsMeta
);

export const history = combineReducers({
    posts,
    statusPosts,
    postsMeta,
});
