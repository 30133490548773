import { createActions } from 'redux-actions';

const {
    getServices,
    getServicesSuccess,
    getServicesFailure,
    getServicesIdle,
}: {
    getServices?: any;
    getServicesSuccess?: any;
    getServicesFailure?: any;
    getServicesIdle?: any;
} = createActions(
    'GET_SERVICES',
    'GET_SERVICES_SUCCESS',
    'GET_SERVICES_FAILURE',
    'GET_SERVICES_IDLE'
);

export { getServices, getServicesSuccess, getServicesFailure, getServicesIdle };
