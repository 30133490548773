import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getService, getServiceSuccess, getServiceIdle } from './actions';
import { ServiceState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: ServiceState = {
    status: 'IDLE',
    data: [],
};

const status = handleActions<StateStatus>(
    {
        [getService]: () => 'LOADING',
        [getServiceSuccess]: () => 'SUCCESS',
        [getServiceIdle]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getServiceSuccess]: (state, action) => action.payload.service.data[0],
    },
    initialState.data
);

export const service = combineReducers({
    status,
    data,
});
