import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getHomepage,
    getHomepageIdle,
    getHomepageSuccess,
    getPosts,
    getPostsSuccess,
    getPostsFailure,
    getPostsIdle,
    getPostsClear,
} from './actions';
import { HomepageState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: HomepageState = {
    status: 'IDLE',
    topServices: [],
    categories: [],
    posts: [],
    statusPosts: 'IDLE',
    postsMeta: null,
    // postsTopTitle: '',
    // postsTop: [],
    collectionsPosts: [],
};

// const postsTopTitle = handleActions(
//     {
//         [getHomepageSuccess]: (state, action) => action.payload.postsTopTitle,
//     },
//     initialState.postsTopTitle
// );

// const postsTop = handleActions(
//     {
//         [getHomepageSuccess]: (state, action) => action.payload.postsTop,
//     },
//     initialState.postsTop
// );

const topServices = handleActions(
    {
        [getHomepageSuccess]: (state, action) => action.payload.topServices,
    },
    initialState.topServices
);

const categories = handleActions(
    {
        [getHomepageSuccess]: (state, action) => action.payload.categories,
    },
    initialState.categories
);

const statusPosts = handleActions<StateStatus>(
    {
        [getPosts]: () => 'LOADING',
        [getPostsSuccess]: () => 'SUCCESS',
        [getPostsIdle]: () => 'IDLE',
    },
    initialState.statusPosts
);

const posts = handleActions(
    {
        [getPostsSuccess]: (state, action) => {
            return [...state, ...action.payload.posts.data];
            // let uniq: any = [];
            // action.payload.posts.data.map((item: any) => {
            //     if(state.findIndex((el: any) => el.id == item.id) < 0){
            //         uniq.push(item);
            //     }
            // })
            // return [...state, ...uniq];
        },
        [getPostsClear]: () => [],
    },
    initialState.posts
);

const postsMeta = handleActions(
    {
        [getPostsSuccess]: (state, action) => action.payload.posts.meta,
        [getPostsClear]: () => null,
    },
    initialState.postsMeta
);

const collectionsPosts = handleActions(
    {
        [getHomepageSuccess]: (state, action) =>
            action.payload.collectionsPosts,
    },
    initialState.collectionsPosts
);

const status = handleActions<StateStatus>(
    {
        [getHomepage]: () => 'LOADING',
        [getHomepageSuccess]: () => 'SUCCESS',
        [getHomepageIdle]: () => 'IDLE',
    },
    initialState.status
);

export const homepage = combineReducers({
    status,
    topServices,
    categories,
    posts,
    statusPosts,
    postsMeta,
    // postsTopTitle,
    // postsTop,
    collectionsPosts,
});
