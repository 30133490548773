import { token, main_domain } from 'config';
import fetch from 'isomorphic-fetch';

export const getOgReguest = (request: any) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Content-Type', 'application/json');

        var requestOptions: {
            method: any;
            headers: any;
            redirect: any;
            body: any;
        } = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(request),
        };

        fetch(main_domain + '/og/', requestOptions)
            .then(response => response.json())
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                console.log('error', error);
                reject(error);
            });
    });
};
