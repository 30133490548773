import { fork, all } from 'redux-saga/effects';

import { homepageWatcher } from './ducks/homepage/saga';
import { historyWatcher } from './ducks/history/saga';
import { offersWatcher } from './ducks/offers/saga';
import { servicesWatcher } from './ducks/services/saga';
import { searchWatcher } from './ducks/search/saga';
import { serviceWatcher } from './ducks/service/saga';
import { postWatcher } from './ducks/post/saga';
import { offerWatcher } from './ducks/offer/saga';
import { getInitDataWatcher } from './ducks/app/saga';
import { routerSaga } from './ducks/router/saga';
import { eventsWatcher } from './ducks/events/saga';
import { eventWatcher } from './ducks/event/saga';

export default function* rootSaga() {
    yield all([
        fork(homepageWatcher),
        fork(getInitDataWatcher),
        fork(routerSaga),
        fork(serviceWatcher),
        fork(searchWatcher),
        fork(servicesWatcher),
        fork(postWatcher),
        fork(offerWatcher),
        fork(historyWatcher),
        fork(offersWatcher),
        fork(eventsWatcher),
        fork(eventWatcher),
    ]);
}
