import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getServices, getServicesSuccess, getServicesIdle } from './actions';
import { ServicesState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: ServicesState = {
    status: 'IDLE',
    data: [],
};

const status = handleActions<StateStatus>(
    {
        [getServices]: () => 'LOADING',
        [getServicesSuccess]: () => 'SUCCESS',
        [getServicesIdle]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getServicesSuccess]: (state, action) => action.payload.services.data,
    },
    initialState.data
);

export const services = combineReducers({
    status,
    data,
});
