import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getServices,
    getServicesSuccess,
    getServicesFailure,
    getServicesIdle,
} from './actions';

import { Post } from 'types/models';
import { getReguest } from '../app/api';

function* getSagaServices(action: any): any {
    try {
        const { payload } = action;

        let route =
            '/api/service-categories?sort=Sort&fields[0]=Title&fields[1]=Description&populate=Services.Preview&pagination[pageSize]=100';

        const [services] = yield all([call(getReguest, { route: route })]);

        yield put(
            getServicesSuccess({
                services,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getServicesFailure(error));
    }
}

export function* servicesWatcher() {
    yield takeEvery(getServices.toString(), getSagaServices);
}
