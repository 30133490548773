import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { State } from 'types';
import { homepage } from './ducks/homepage/reducer';
import { history } from './ducks/history/reducer';
import { offers } from './ducks/offers/reducer';
import { services } from './ducks/services/reducer';
import { search } from './ducks/search/reducer';
import { service } from './ducks/service/reducer';
import { post } from './ducks/post/reducer';
import { offer } from './ducks/offer/reducer';
import { initData } from './ducks/app/reducer';
import { events } from './ducks/events/reducer';
import { event } from './ducks/event/reducer';

export default (historys: History) =>
    combineReducers<State>({
        homepage,
        events,
        service,
        services,
        search,
        initData,
        history,
        offers,
        post,
        offer,
        event,
        router: connectRouter(historys),
    });
