import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
    getSearchClear,
} from './actions';
import { SearchState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: SearchState = {
    status: 'IDLE',
    data: [],
    meta: null,
    categories: {},
};

const status = handleActions<StateStatus>(
    {
        [getSearch]: () => 'LOADING',
        [getSearchSuccess]: () => 'SUCCESS',
        [getSearchIdle]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getSearchSuccess]: (state, action) => action.payload.posts.data,
        [getSearchClear]: (state, action) => [],
    },
    initialState.data
);

const categories = handleActions(
    {
        [getSearchSuccess]: (state, action) => action.payload.categories,
        [getSearchClear]: (state, action) => [],
    },
    initialState.categories
);

const meta = handleActions(
    {
        [getSearchSuccess]: (state, action) => action.payload.posts.meta,
        [getSearchClear]: (state, action) => null,
    },
    initialState.meta
);

export const search = combineReducers({
    status,
    data,
    meta,
    categories,
});
