import { createActions } from 'redux-actions';

const {
    getEvent,
    getEventSuccess,
    getEventFailure,
    getEventIdle,
}: {
    getEvent?: any;
    getEventSuccess?: any;
    getEventFailure?: any;
    getEventIdle?: any;
} = createActions(
    'GET_EVENT',
    'GET_EVENT_SUCCESS',
    'GET_EVENT_FAILURE',
    'GET_EVENT_IDLE'
);

export { getEvent, getEventSuccess, getEventFailure, getEventIdle };
