import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getOffer, getOfferSuccess, getOfferIdle } from './actions';
import { OfferState } from './types';
import { StateStatus, Post } from 'types/models';

export const initialState: OfferState = {
    status: 'IDLE',
    data: {},
    posts: [],
};

const status = handleActions<StateStatus>(
    {
        [getOffer]: () => 'LOADING',
        [getOfferSuccess]: () => 'SUCCESS',
        [getOfferIdle]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getOfferSuccess]: (state, action) => ({
            ...action.payload.post.data[0],
            ogurl: action.payload.post.ogurl,
        }),
    },
    initialState.data
);

const posts = handleActions(
    {
        [getOfferSuccess]: (state, action) => {
            return [...state, ...action.payload.posts.data];
            // let uniq: any = [];
            // action.payload.posts.data.map((item: any) => {
            //     if(state.findIndex((el: any) => el.id == item.id) < 0){
            //         uniq.push(item);
            //     }
            // })
            // return [...state, ...uniq];
        },
    },
    initialState.posts
);

export const offer = combineReducers({
    status,
    data,
    posts,
});
