import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import {
    getEvent,
    getEventSuccess,
    getEventFailure,
    getEventIdle,
} from './actions';

import { Post } from 'types/models';
import { getReguest } from '../app/api';

function* getSaga(action: any): any {
    try {
        const { payload } = action;

        const { slug } = payload;

        let route =
            '/api/events/?filters[Slug]=' +
            slug +
            '&fields[0]=Title' +
            '&fields[1]=Description' +
            '&fields[2]=Info_date' +
            '&fields[3]=Title_gallery' +
            '&fields[4]=OG_Title' +
            '&fields[5]=OG_Description' +
            '&fields[6]=Title_gallery_2' +
            '&fields[7]=Start' +
            '&populate[0]=OG_Image' +
            '&populate[1]=Content' +
            '&populate[2]=Hero_background' +
            '&populate[3]=Categories' +
            '&populate[4]=Gallery' +
            '&populate[5]=Label_img' +
            '&populate[6]=Gallery_2' +
            '&pagination[pageSize]=1' +
            '&sort=Start:desc';

        console.log(route);

        const [event] = yield all([call(getReguest, { route: route })]);

        console.log(event);

        yield put(
            getEventSuccess({
                event,
            })
        );
    } catch (error) {
        // console.log(error);
        yield put(getEventFailure(error));
    }
}

export function* eventWatcher() {
    yield takeEvery(getEvent.toString(), getSaga);
}
